































































































































































































































































































































































































































































































.main-wrapper {
  height: 100%;
  box-sizing: border-box;
  padding: 30px 30px 0 10px;
  .el-scrollbar {
    margin: 20px;
    height: calc(100% - 100px);
  }

  ::v-deep .el-scrollbar__wrap {
    height: 100%;
    overflow-x: hidden;

    .el-scrollbar__view {
      height: 100%;
    }
  }
}
.quality-course-content {
  flex: 1;
  // display: flex;
  overflow: hidden;
  margin-bottom: 37px;
  .content-item-left {
    * {
      box-sizing: border-box;
    }

    width: 60%;
    box-sizing: border-box;
  }
  .content-item-right {
    * {
      box-sizing: border-box;
    }
    min-width: 636px;
    width: 40%;
    box-sizing: border-box;
  }
  .content-left {
    height: 100%;
    background-color: #fff;
    overflow: auto;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;

      .el-scrollbar__view {
        padding: 0;
      }
    }
    .border {
      display: inline-block;
      width: 6px;
      height: 12px;
      margin-right: 10px;
      background-color: rgb(40, 33, 252);
      border-radius: 5px;
    }
    .el-input {
      width: 300px;
    }
    ::v-deep .el-input__inner {
      text-align: left;
    }
    .el-input-number {
      width: 300px;
    }
  }
  .content-right {
    height: 100%;
    background-color: #fff;
    overflow: auto;
    padding-left: 40px;
    margin-top: 20px;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .border {
      display: inline-block;
      width: 6px;
      height: 12px;
      margin-right: 10px;
      background-color: rgb(40, 33, 252);
      border-radius: 5px;
    }
    .cont {
      padding: 20px 55px;
      background-color: #f7f8fc;
    }
    .logo-src-box {
      display: flex;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      border: 1px solid #eeeeee;
      position: relative;
      img {
        max-width: 100%;
        max-height: 100%;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border: 13px solid red;
        border-color: red red transparent transparent;
        i {
          position: absolute;
          right: -11px;
          top: -11px;
          font-size: 18px;
          line-height: 13px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .logo-uploader {
      ::v-deep.el-upload {
        width: 80px;
        height: 80px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo-uploader-icon {
          font-size: 20px;
          color: #a0a0a0;
        }
      }
    }
    .img-arr {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .log-img {
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(5) {
          margin-right: 0px;
        }
        &:nth-child(10) {
          margin-right: 0px;
        }
      }
    }
    .chosen {
      border: solid 2px #3089dc !important;
    }
    .imgName {
      text-align: center;
      color: #ababbb;
      padding-top: 10px;
    }
    .paster-left {
      margin-right: 40px;
    }
    .paster-right {
      padding-top: 20px;
    }
  }
}
.footer-button {
  text-align: center;
}
